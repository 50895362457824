.gjs-row {
  border: 1px dotted red;
}

.gjs-cell {
  border: 1px dotted blue;
}

.gjs-editor {
  font-family: "Roboto", sans-serif;
}

.gjs-pn-panel {
  padding: 16px;
  background: #fff;
}

.gjs-pn-devices {
  left: 500px;
}

.gjs-block-category {
  background: #fff;
}

.gjs-cv-canvas {
  top: 63px;
}

.gjs-pn-views-container {
  padding: 76px 10px 30px;
  background: #F3F3F3;
}

/* //Accordion global-changes */

.gjs-block-category {
  margin-bottom: 10px;
}

.gjs-sm-sector {
  background-color: #fff;
  margin-bottom: 10px;
}

.gjs-sm-sector .gjs-sm-sector-caret,
.gjs-block-category .gjs-caret-icon {
  position: absolute;
  right: 20px;
  transform: rotate(-90deg);
}

.gjs-block-category.gjs-open .gjs-caret-icon {
  transform: rotate(0);
}

.gjs-sm-sector .gjs-sm-property,
.gjs-sm-sector .gjs-fields {
  background: #fff;
  min-height: 32px;
}

.gjs-pn-views-container .gjs-block-category:last-of-type {
  margin: 0;
}

.gjs-block-category .gjs-title,
.gjs-sm-sector .gjs-sm-sector-title {
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
  border: solid 1px #DADADA;
  color: #000000;
  border-radius: 2px;
  font-weight: 700;
}

.gsm-sector-field {
  display: none;
}

.gsm-open+.gsm-sector-field {
  display: block;
}

.gjs-block-label {
  font-family: "Roboto", sans-serif;
}

.gjs-sm-sector-title.gsm-open .gjs-sm-sector-caret {
  transform: rotate(0deg);
}

.gjs-sm-sector .gjs-sm-sector-caret {
  width: 25px;
  height: 25px;
}

.accordion-global-body {
  padding: 5px;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: #1276D3;
}

.gjs-trt-traits.gjs-one-bg {
  padding: 20px 5px 5px 5px;
  background: #fff;
}

.gjs-trt-trait__wrp {
  margin-bottom: 10px;
}

.gjs-trt-trait__wrp .gjs-label-wrp {
  max-width: 110px;
  width: 100%;
}

.gjs-trt-trait__wrp .gjs-label {
  font-weight: normal;
}

.gjs-field-wrp--custom-toggle .gjs-field-custom-toggle {
  background: #fff;
}

.gjs-field-custom-toggle input[type="checkbox"] {
  z-index: -1;
}

.gjs-field.gjs-field-custom-select {
  background: #f4f3f3;
}

.gjs-trt-trait--data-mapping-trait {
  padding: 0;
  margin: 0 -5px;
  border-top: solid 1px #F3F3F3;
}

.gjs-trt-trait--data-mapping-trait .gjs-field {
  background: transparent;
}

.gjs-field-wrp--custom-select>div {
  width: 100%;
  height: 32px;
}

.gjs-pn-panel .gjs-traits-label {
  text-align: center;
  font-size: 16px;
  background: #fff;
  color: #727272;
}

.gjs-block {
  justify-content: space-evenly;
}

.gjs-block.gjs-one-bg {
  background-color: #F5FBFF;
}

.gjs-block__media img {
  width: 32px;
}

/* .gjs-field {
  background: #fff;
} */

.gjs-field input {
  background: #fff;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  height: 32px;
  border-color: rgba(0, 0, 0, 0.23);
}

.gjs-field-units select {
  height: 32px;
}

.gjs-field textarea {
  resize: none;
  background: #fff;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  border-color: rgba(0, 0, 0, 0.23);
  display: block;
  white-space: normal;
  text-align: left;
}

/* Typograpy css */

.color-box-container {
  display: flex;
}

.color-box-container .color-block {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  border-radius: 4px;
}

.color-wrapper {
  width: 20px;
}

.color-wrapper .gjs-sm-field {
  margin-bottom: 20px;
}

.custom-pagebuilder .css-d9c359-MuiButtonBase-root-MuiToggleButton-root {
  padding: 13px;
}

.global-block-headings {
  font-size: 16px;
  line-height: 22px;
  margin: 12px 0;
  color: #000;
}

span[title="View code"],
span[title="Open Style Manager"],
span[title="Clear Canvas"] {
  display: none;
}

.custom-pagebuilder.preview .topbar {
  display: none;
}

.topbar {
  position: absolute;
  top: 0;
  height: 67px;
  padding: 12px;
  display: flex;
  align-items: center;
  z-index: 9;
  left: 0;
}

.topbar figure {
  width: 120px;
  height: 40px;
  overflow: hidden;
  margin-right: 30px;
}

.topbar figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custom-pagebuilder .MuiToggleButton-root {
  color: #000000;
  font-size: 12px;
  line-height: 14px;
  background: #F3F3F3;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px;
  margin-right: 5px;
}

.custom-pagebuilder .MuiToggleButton-root.Mui-selected {
  background: #1276D3;
  color: #fff;
  border-radius: 2px;
  font-weight: 700;
}

.custom-pagebuilder .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type),
.custom-pagebuilder .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.custom-pagebuilder .MuiToggleButton-root.Mui-selected:hover {
  background: #1276D3;
}

.custom-pagebuilder .MuiAccordion-root {
  box-shadow: none;
}

.custom-pagebuilder .MuiTypography-root {
  color: #8a8a8a;
  font-size: 12px;
}

.more-appsoverlay {
  position: absolute;
  background: #fff !important;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border: none;
  color: #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin-top: 4px;
  left: 0px;
  padding: 20px;
}

.more-appsoverlay ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  grid-gap: 15px;
}


.more-appsoverlay li {
  text-align: center;
  white-space: initial !important;
  padding: 0px;
  font-size: 12px;
  color: #949494;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #F2F2F2;
  border-radius: 6px;
}

.more-appsoverlay li a {
  color: #7F7F7F;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
  width: 66px;
  flex-direction: column;
  height: 100%;
}

.more-appsoverlay li i {
  display: block;
  margin-bottom: 7px;
  display: block;
  height: 25px;
  width: 25px;
  overflow: hidden;
}

.more-appsoverlay li svg {
  fill: #999;
}

.more-appsoverlay li:hover svg {
  fill: #1276D3;
}

.more-appsoverlay li:hover {
  /* background-color: rgba(0, 0, 0, 0.15); */
  color: #1276D3;
}

.more-appsoverlay li:hover a {
  color: #1276D3;
}

.more-appsoverlay li a[href="/"] {
  filter: grayscale(100%);
  pointer-events: none;
  cursor: default;
}
.gjs-field .MuiInputBase-root input {
  position: absolute !important;
}

.gjs-field .MuiInputBase-root #autocomplete-select-container-id {
  position: relative !important;
  border: 0;
  display: block;
}

.autocomplete-name {
  font-size: 16px;
}

.autocomplete-msid-url {
  font-size: 12px;
  word-break: break-all;
}

.autocomplete-status {
  font-size: 12px;
  color: green;
}

.MuiAutocomplete-popupIndicator {
  display: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  min-height: 35px;
}

.more-appsoverlay ul li a[href="javascript:void(0)"] {
  opacity: .4;
  pointer-events: none;
}

.more-appsoverlay ul li a[href="javascript:void(0)"]:hover p {
  color: #000 !important;
  opacity: .4;
}
.more-appsoverlay li:hover a[href="javascript:void(0)"] {
  color: #7f7f7f;
}