.errorwrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
    background-color: #F8F8F8;
    padding: 25px 0px 35px;
}

.errortext {
    margin-bottom: 7px;
}

.erroeheading .errortext {
    font-family: "Poppins", sans-serif;
    font-size: var(--fontSize-80);
    font-weight: var(--fontWeight7);
    line-height: 120px;
    letter-spacing: var(--letterSpacing);
    color: var(--primary);
} 

.errorcontent h3 {
    font-family: "Poppins", sans-serif;
    font-size: var(--fontSize-18);
    font-weight: var(--fontWeight4);
    line-height: 27px;
    letter-spacing: var(--letterSpacing);
    text-align: center;
    max-width: 463px;
    width: 100%;
    margin: 0px;
}


@media screen and (min-width:320px) and (max-width:767px) {
    .erroeheading .errortext {
        font-size: var(--fontSize-60);
        line-height: 60px;
        letter-spacing: var(--letterSpacing);

    }

    .errorcontent h3 {
        font-size: var(--fontSize-16);
        line-height: 24px;
    }

    .errorwrapper {
        padding: 0px 30px;
    }
}