.RectangleFeed {
    /* width: 800px; */
    height: 589px;
    top: 205px;
    left: 304px;
    gap: 0px;
    border-radius: 4px 0px 0px 0px;
    /* opacity: 0px; */
}

.LandingTopWrapperCss {
    border-radius: 14px;
    /* border: 1px solid #E9F6FF; */
    background: #FFF;
    padding: 22px;
}

.MuiDialog-paper.MuiDialog-paperScrollPaper {
    max-width: 914px;
    /* width: 914px; */
    /* max-height: 610px; */
}

.inputUpperTextNewFeed {
    color: black;
    text-align: left;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.filteringOptions {
    color: black !important;
    font-family:  "Poppins", sans-serif;
    font-size: 20px !important;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
}

.RectangleFeed .customInputFiled input {
    padding: 12px;
    font-family:  "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: left;

}


.RectangleFeed .customInputFiled {
    display: flex;
    margin: 0px 0px 20px;
    font-family:  "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: left;
}

.MuiFormControl-root.formControlWrapper {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.closePopup .MuiDialog-paper.MuiDialog-paperScrollPaper {
    width: 520px;
    max-width: 520px;
    height: 196px;
}

.inline-container {
    display: flex;
    align-items: center;
}

#menu- .MuiMenu-list {
    padding: 0px;
}

.css-zun73v.Mui-checked.addNewFeedCheckbox {
    color: #3B4256;
}

.LandingTopWrapperCss .css-igoqhi .MuiOutlinedInput-root {
    padding: 5px;
}

.css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline {
    border-color: #58A0E4 !important;
}

.css-fvipm8:hover .MuiOutlinedInput-notchedOutline {
    border-color: #58A0E4 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    font-size: unset;
}

.css-segi59:hover .MuiOutlinedInput-notchedOutline {
    border-color: #58a0e4 !important;
}

p.staticTextStyling {
    white-space: nowrap;
}