.autocomplete-name {
    font-size: 16px;
}

.autocomplete-msid-url {
    font-size: 12px;
    word-break: break-all;
}

.autocomplete-status {
    font-size: 12px;
    color: green;
}

.manageHeightTextfild .MuiInputBase-fullWidth.MuiInputBase-formControl{
    padding: 5px 9px;
}