.feedName {
    font-family:  "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03999999910593033px;
    text-align: left;
    opacity: .89;

}

.LandingTopWrapper .MuiAccordionSummary-content {
    align-items: center;
}

.feedId {
    margin-left: 5px;
    font-family:  "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.03999999910593033px;
    text-align: left;
}

.textStyling {
    font-family:  "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.009999999776482582px;
    text-align: left;

}

.staticTextStyling {
    font-family:  "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.009999999776482582px;
    text-align: left;
    font-weight: 600;
    margin-right: 4px;
}


.feedAccordian {
    display: flex;
    justify-content: space-between;
}

.NoFeedText {
    font-family:  "Poppins", sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-align: center;
}

.accordianSummaryClass {
    align-items: center;
}

.accordianClass {
    background: #f8f8f8 !important;
    margin-bottom: 10px;
    border-bottom: 1px solid #E0E0E0;
    box-shadow: none !important;
}

.accordianClass::before {
    display: none !important;
}

.accordianClass:last-child {
    margin-bottom: 0px;
}

.Mui-expanded.accordianClass {
    background: #FDFDFD !important;
}

.accordianClass .accordianSummaryClass {
    background: #F8F8F8 !important;
}

.buttonCreateFeed {
    width: 154px;
    height: 40px;
    gap: 0px;
    background: #0C6ECC;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background: #F8F8F8 !important;
}

.inline-container {
    display: flex;
    align-items: center;
}

.inline-container-feed {
    display: flex;
}