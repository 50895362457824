@import url(../../index.css);



.MainBodyWrapper {
    background-color: #ebf7ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

}

.fix-wrapper {
    width: 100%;
    max-width: 1190px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}

.LandingTopWrapper {
    background: #fff;
    border: 1px solid #e9f6ff;
    border-radius: 14px;
    padding: 32px 32px 48px;
}

.LandingPageHeaderWrapper {
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.inNewsroomTiitle {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}

.RightSideMenuWrap {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.MenuSelectItems .MuiFormControl-root {
    min-width: 200px;
    margin: 0px;
}

.MenuSelectItems .MuiSelect-select.MuiSelect-outlined {
    outline: 0px;
    border: 0px;
    color: #0C6ECC;
    padding: 0px;
    font-size: 14px;

}


.MenuSelectItems .MuiOutlinedInput-notchedOutline {
    border: 0px;
}

.slectItemsCommon.MuiButtonBase-root.MuiMenuItem-root img {
    width: 20px;
    margin-right: 12px;
}

.MenuSelectItems ul {
    padding: 0px;
}

.slectItemsCommon.MuiButtonBase-root.MuiMenuItem-root {
    font-size: 14px;
    /* padding: 0px; */
    margin-bottom: 12px;
}

.MenuSelectItems .MuiSelect-select.MuiSelect-outlined img {
    width: 20px;
    border-radius: 100%;
    margin-right: 6px;
}

/* .slectItemsCommon.MuiButtonBase-root.MuiMenuItem-root:hover{
    background-color: transparent;
}

.slectItemsCommon.MuiButtonBase-root.MuiMenuItem-root:first-child{
    display: block;
} */
/*-----website list----------*/

.muiWebsiteListWrap {
    position: relative;
}

.muiWebsiteListWrap button,
.muiWebsiteListWrap .webDropdownMenu li a {
    background: transparent;
    border: 0px;
    color: #0C6ECC;
    cursor: pointer;
}

.muiWebsiteListWrap button img,
.muiWebsiteListWrap .webDropdownMenu li a img {
    width: 20px;
    border-radius: 100%;
    margin-right: 6px;
}

.muiWebsiteListWrap button {
    background: transparent;
    border: 0px;
    color: #0C6ECC;
}

.muiWebsiteListWrap button img {
    width: 20px;
    border-radius: 100%;
    margin-right: 6px;
}

.muiWebsiteListWrap .webDropdownMenu {
    position: absolute;
    background: #fff;
    width: 220px;
    top: 100%;
    background: #fff;
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    border-radius: 4px;
    min-width: 220px;
    max-width: 300px;
    left: 0px;
    padding: 10px;
}

.muiWebsiteListWrap .webDropdownMenu li {
    margin-bottom: 8px;
}

.muiWebsiteListWrap .webDropdownMenu li:last-child {
    margin-bottom: 0px;
}

/*-----website list----------*/
.LandingPageHeader {
    background: #fff;
    margin-bottom: 36px;
    box-shadow: 0 0 5px #dbdbdb;
}

.AppsIconsWrap {
    margin: 0px 25px;
    position: relative;
}

.AppsIconsWrap button {
    cursor: pointer;
    border: 0px;
    background: #fff;
    color: #7F7F7F;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.UserNameWrap {
    position: relative;
}

.logOutWrap {
    position: absolute;
    width: 220px;
    top: 50px;
    background: #fff;
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    border-radius: 4px;
    min-width: 220px;
    max-width: 300px;
    right: 0px;
    z-index: 9999;
}



.userDetailsWrap {
    padding: 7px 16px 15px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
}

.userNameDetails {
    font-size: 16px;
    text-transform: capitalize;
}

.userEmailDetails {
    font-size: 11px;
    color: #666;
    margin-top: 5px;
}

.logOutWrap button {
    width: 100%;
    background-color: transparent;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    font-size: 16px;
}

.logOutWrap button svg {
    margin-right: 16px;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    color: rgba(0, 0, 0, .5);
}

.activeState .dotsButton {
    background-color: #D9D9D9;
}

.UserNameWrap .logButtonDrop {
    border: 0px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: #0C6ECC;
    border-radius: 100%;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ShowIconsOnClick {
    background: #fff;
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 10px #0003;
    color: #000;
    margin-top: 4px;
    padding: 20px;
    position: absolute;
    right: 18px;
}

.ShowIconsOnClick ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    grid-gap: 15px;
}

.ShowIconsOnClick ul li {
    color: #7F7F7F;
    font-size: 12px;
    /* padding: 15px 0px; */
    text-align: center;
    white-space: normal;
    height: 84px;
    /* width: 66px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F2F2F2;
    border-radius: 6px;
}

.ShowIconsOnClick li a {
    color: #7F7F7F;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 84px;
    width: 66px;
    flex-direction: column;
    height: 100%;
}

.ShowIconsOnClick li a span {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    display: inline-block;
    line-height: 14px;
}

.ShowIconsOnClick li a[href="/"] {
    filter: grayscale(100%);
    pointer-events: none;
    cursor: default;
}

/* .ShowIconsOnClick li:hover {
    background-color: #0C6ECC;
} */

.ShowIconsOnClick li:hover a {
    color: #0C6ECC;
}

.ShowIconsOnClick li:hover .ShowIconsOnClick li i {
    display: block;
    height: 20px;
    margin-bottom: 7px;
    overflow: hidden;
}

.IconWrap {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
}

/* .IconWrap figure {
    position: absolute;
}

.nineDotsIconSelected {
    opacity: 0;
}

.ShowIconsOnClick li a:hover .nineDotsIcon {
    opacity: 0;
}

.ShowIconsOnClick li a:hover .nineDotsIconSelected {
    opacity: 1;
} */

.Rowside {
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin: 36px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    grid-gap: 36px;
}

.LandingHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LandingHeadingPopup {
    align-items: center;
    justify-content: space-between;
}

.LandingHeading .welcomeText {
    font-family: "Poppins", sans-serif;
    color: #0C6ECC;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    order: 1;

}

.LandingHeadingPopup .welcomeText {
    font-family: "Poppins", sans-serif;
    color: #0C6ECC;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    order: 1;

}


.LandingHeading .buttonCreateFeed {
    order: 2;
}

.LandingHeading p span {
    font-family: "Poppins", sans-serif;
    color: #757575;
    font-weight: 400;
}

.LandingHeadingPopup .buttonCreateFeed {
    order: 2;
}

.LandingHeadingPopup p span {
    font-family: "Poppins", sans-serif;
    color: #757575;
    font-weight: 400;
}

.LandingWelcomeText p {
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.14px;
}

/* .CommonLandingTIles {
    margin-right: 36px;
    margin-bottom: 35px;
}

.LandingPageTiles.CommonLandingTIles:nth-child(3n) {
    margin-right: 0px;
} */


.CommonLandingTIles {
    padding: 25px 15px 21px 26px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #5AA1E6;
    background: #FFF;
    box-shadow: -2px 2px 0px 0px #58A0E4;
}

.CommonLandingTIles:hover {
    color: #fff;
    position: relative;
    border-radius: 8px;
    background: linear-gradient(180deg, #5FA7EC 0%, #004282 100%);
    box-shadow: -4px 4px 0px 0px #C6D8E8;
}


.LandingtilesHeadingWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.UserSelectedImage {
    opacity: 0;
}

.IconWrapper {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    position: relative;
    border-radius: 6px;
    background: #E9F6FF;
    padding: 8px;
}

.LandingHeading {
    margin-bottom: 10px;
}

.LandingHeadingPopup {
    margin-bottom: 10px;
}

/* .CommonLandingTIles:hover .IconWrapper {
    background: #fff;
}

.CommonLandingTIles:hover .afterHover {
    opacity: 1;
}

.CommonLandingTIles:hover .current {
    opacity: 0;
}

.IconWrapper figure {
    position: absolute;
} */


.LandingtilesHeadingWrapper p {
    font-family: "Poppins", sans-serif;
    color: #498FD3;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.19px;

}

.LandingTilesText p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: 0.14px;
    color: #333;
}

.LandingWelcomeText p span {
    font-style: italic;
    font-weight: 700;
}

.LandingTilesLinks {
    margin-top: 10px;
}

.LandingTilesLinks ul {
    list-style: disc;
}

.LandingTilesLinks ul a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: 0.14px;
    color: rgba(51, 51, 51, 0.85);
}


.CommonLandingTIles:hover .LandingtilesHeadingWrapper p,
.CommonLandingTIles:hover .LandingTilesText p,
.CommonLandingTIles:hover .LandingTilesLinks ul a {
    color: #fff;
}

.CommonLandingTIles:hover .IconWrapper {
    background-color: #fff;
}

.landingPageFooter {
    background: #fff;
    padding: 10px 0px;
    margin-top: auto;
}

.MainBodyWrapper .fix-wrapper {
    margin-bottom: 30px;
}
.landingFooterText p {
    color: rgba(51, 51, 51, 0.85);
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    /* 185.714% */
    letter-spacing: 0.14px;
}

.landingFooterLinks ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}


.landingFooterLinks ul li a {
    color: rgba(51, 51, 51, 0.85);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.14px;
    display: inline-block;
}

.landingFooterLinks ul li a:after {
    content: "|";
    margin: 0px 10px;
}

.landingFooterLinks ul li a:last-child:after {
    content: '';
    margin: 0px;
}


.tremsAndConditionStaticContentheading {
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}

.tremsAndConditionStaticContent {
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.14px;
    text-align: justify;
}

.tremsAndConditionStaticContent a,
.tremsAndConditionStaticContent span {
    color: #000;
    text-decoration: underline !important;
    font-weight: 500;
}

.tremsAdress,
.abcList,
.romanNumber {
    margin-bottom: 15px;
    padding-left: 0px;
}

.tremsAdress li,
.abcList li,
.romanNumber li,
.numberList li {
    font-family: "Poppins", sans-serif;
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.14px;
    margin-bottom: 10px;
    /* padding-left: 20px; */
}

ul.tremsAdress {
    padding: 0px;
}

.numberList,
.romanNumber {
    margin-bottom: 15px;
    padding: 0px;
}

.bulletList {
    list-style-type: disc;
}

.bulletList li {
    margin-bottom: 10px;
}

.tremsAdress li {
    margin-bottom: 5px;
}

.numericlist ol {
    padding: 0px;
}

/* css handles the parentheses/brackets */
.laparent ol,
.numericlist ol,
.romanNumber ol {
    counter-reset: item
}

.laparent li,
.numericlist li,
.romanNumber li {
    display: block;
    counter-increment: item;
    margin-bottom: 10px;
}

/* replace the counter before list item with
          open parenthesis + counter + close parenthesis */
.romanNumber li:before {
    content: " (" counter(item, lower-roman) ") ";
}

.numericlist li:before {
    content: " (" counter(item, decimal) ") ";
}

.laparent li:before {
    content: " (" counter(item, lower-alpha) ") ";
}

@media(max-width:520px){

    .CommonLandingTIles {
        display: block;
        margin-bottom: 20px;
    }
    .Rowside {
        display: block;
        margin: 36px 0px 0px;
    }
    .CommonLandingTIles:last-child {
        margin-bottom: 0px;
    }
}