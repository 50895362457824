.makeStyles-root-1 {
    /* width: max-content; */
    display: flex;
    flex-direction: row;
    margin-left: -7px;
    margin-bottom: 8px;
}

.inputBoxStyle {
    height: 1.4375em;
    width: -webkit-fill-available;
    padding: 16.5px 14px;
}

.inputBoxStyleNumber {
    height: 1.4375em;
    width: -webkit-fill-available;
    padding: 16.5px 14px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.inputBoxStyleNumber::-webkit-scrollbar {
    display: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.deleteIcon1 {
    display: flex;
    justify-content: right;
    color: #ccc;
    top: 10px;
    position: relative;
    cursor: pointer;
}