.inputTextForConfigFeed {
    font-family:  "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 0px;

}

.inputTextItemWrapper {
    flex-shrink: 0;
    width: 25%;
}

.formControlWrapperFild {
    flex-shrink: 0;
    width: 100%;
    max-width: 250px;
}

.mainFormCntWrapper .css-zun73v.Mui-checked,
.mainFormCntWrapper .css-zun73v.MuiCheckbox-indeterminate {
    color: #3B4256;
}

.mainFormCntWrapper .css-zun73v.Mui-checked.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
}

.mainFormCntWrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
}

.checkBoxWraper {
    margin-right: 10px;
    display: block;
}
.checkBoxWraper span {
    padding: 0px 0px;
}

.formControlWrapperFild .customInputFiled {
    margin-bottom: 0px;
}

.mainFormCntWrapper:first-child {
    margin-top: 10px;
}

/* .mainFormCntWrapper:last-child {
    margin-bottom: 0px;
} */

.LandingTopWrapperCss .LandingHeading .welcomeText {
    color: #0c6ecc;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: .2px;
    line-height: 36px;
    order: 1;
}

.mainFormCntWrapper .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
}

.formSelectWrapper .customInputFiled {
    margin-bottom: 0px;
    margin-left: 15px;
}

.formControlWrapperFild .css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline {
    border-color: #58A0E4;
}

.formSelectWrapper .css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline {
    border-color: #58A0E4;
}

.formControlWrapperFild:has(.MuiFormHelperText-root) fieldset {
    border: 1px solid red;
}