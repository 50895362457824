@import url(../../index.css);

/*----------------------------*/
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background-color: rgba(0, 0, 0, .8) !important;
}

.MuiDialog-paper.MuiDialog-paperScrollPaper {
    max-width: 914px;
    width: 914px;
    max-height: 610px;
}

.formWrapperGroup {
    max-width: 914px;
}

.Rectangle6 {
    min-width: 600px;
    max-width: 914px;
    height: auto;
    background: linear-gradient(180deg, #1F2023 0%, #1F202F 100%);
    padding: 25px 0px;
    max-height: auto;

}

.Rectangle6 .customInputFiled input {
    padding: 12px;
}

.Rectangle6 .customInputFiled {
    display: flex;
    margin: 0px 0px 20px;
    border-radius: 6px;
}

.Literature {
    width: 74px;
    height: 72px;
    margin: 0px auto 15px;
    display: block;
}

.userMailId {
    color: #fff;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    overflow-wrap: break-word;
    text-align: center;
}

.WelcomeToPublishstory {
    color: #fff;
    font-size: 38px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    overflow-wrap: break-word;
    text-align: center;
}

.ToCreateANewWebsitePleaseProvideTheFollowingInformation {
    margin-bottom: 25px;
    color: #fff;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    overflow-wrap: break-word;
    text-align: center;
}

.MuiFormControl-root.formControlWrapper {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.inputUpperText {
    color: #fff;
    text-align: left;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.inputUpperText sup {
    font-size: 16px;
}

.MuiButtonBase-root.continueButton {
    background: #4097C7;
    padding: 13px 40px;
    font-size: 18px;
    line-height: 27px;

}

.customInputFiled .MuiSelect-select {
    padding: 12px;
}

/*------page-2-----------_*/
.ToCreateANewWebsitePleaseProvideTheFollowingInformation.pageTwo {
    margin-bottom: 10px;
}

.MuiFormLabel-root.selectFork {
    color: #fff;
}

.MuiFormGroup-root.radioButtonWrapper {
    background: #fff;
    border-radius: 5px;
    margin-top: 45px;
    margin-bottom: 50px;
}

.MuiFormGroup-root.radioButtonWrapper label {
    border-bottom: 1px solid #D9D9D9;
    padding: 11px 0px;
    margin-left: 0px;
    margin-right: 0px;
}


.MuiFormGroup-root.radioButtonWrapper label:last-child {
    border: 0px;
}

.MuiButtonBase-root.commonPageButtons {
    padding: 13px 26px;
    font-size: 18px;
}

.MuiButtonBase-root.enterDetails {
    margin-right: 20px;

}

.MuiButtonBase-root.importDetais {
    background-color: #4097C7;
}

.requiredMsg {
    font-family: 'Poppins';
    margin-top: 24px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
}

/*----------------------------*/
.mainImportWrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 90px 0px 70px;
}

.importadWrapper {
    display: flex;
    align-items: center;
    margin-top: 28px;
}

.importadWrapper svg.fa-check {
    font-size: 32px;
    color: #76c93c;
    margin-right: 37px;
}

.ImportedText p {
    font-size: 18px;
    color: #fff;
    line-height: 21px;
    font-family: "Poppins", sans-serif;
}

.importadWrapper .removeIconMui {
    font-size: 32px;
    color: #fff;
    margin-right: 37px;
}

/*----------------------------*/
.box {
    height: 610px;
    width: 914px;
}

.box .popup {
    height: 610px;
    min-width: 600px;
    max-width: 914px;
}

.box .overlap-group {
    background: linear-gradient(180deg, rgb(31, 32, 35) 0%, rgb(31, 31.64, 47) 100%);
    border-radius: 8px;
    height: 610px;
    position: relative;
    width: 914px;
}

.box .text-wrapper {
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    left: 155px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 244px;
}

.box .div {
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    left: 374px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 151px;
}

.box .welcome-to {
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 38px;
    font-weight: 600;
    left: 157px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 183px;
}

.box .text-input {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    left: 198px;
    position: absolute;
    top: 311px;
    width: 488px;
}

.box .text-input-2 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2px;
    position: relative;
    width: 488px;
}

.box .text-wrapper-2 {
    align-self: stretch;
    color: #ffffff;
    font-family: "IBM Plex Sans-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
}

.box .text-block {
    align-items: flex-start;
    align-self: stretch;
    background-color: #f1e0e0;
    border-radius: 4px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    position: relative;
    width: 100%;
}

.box .text-wrapper-3 {
    align-self: stretch;
    color: #b8bcca;
    font-family: "IBM Plex Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
}

.box .text-input-3 {
    height: 74px;
    left: 198px;
    position: absolute;
    top: 411px;
    width: 488px;
}

.box .text-wrapper-4 {
    color: #ffffff;
    font-family: "IBM Plex Sans-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: -1px;
    width: 488px;
}

.box .div-wrapper {
    background-color: #f8fafc;
    border-radius: 4px;
    height: 48px;
    left: 0;
    position: absolute;
    top: 26px;
    width: 488px;
}

.box .text-wrapper-5 {
    color: #b8bcca;
    font-family: "IBM Plex Sans-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    height: 24px;
    left: 12px;
    letter-spacing: 0.16px;
    line-height: 24px;
    position: absolute;
    top: 11px;
    width: 400px;
}

.box .p {
    color: #f2e1e1;
    font-family: "IBM Plex Sans-Medium", Helvetica;
    font-size: 13px;
    font-weight: 500;
    left: 198px;
    letter-spacing: 0;
    line-height: 19.5px;
    position: absolute;
    top: 385px;
    white-space: nowrap;
}

.box .button {
    height: 48px;
    left: 354px;
    position: absolute;
    top: 514px;
    width: 177px;
}

.box .icon {
    background-image: url(./../../images/book_image.png);
    background-size: 100% 100%;
    height: 80px;
    left: 408px;
    position: absolute;
    top: 56px;
    width: 80px;
}

.checkboxTextCss {
    font-family:  "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 28px;
    color: white;
    position: relative;
    /* top: 7px;
    right: 25px; */
}