$primaryColor: #f4f3f3;
$secondaryColor: #8a8a8a;
$tertiaryColor: #FF6206;
$quaternaryColor: #0098CC;
// $colorHighlight: #FF6206;

$mainFont:          Helvetica, sans-serif;
$mainDkColor:       #dbdbdb;/* darken($mainColor, 4%) - #383838 */
$mainDklColor:      transparent;/* rgba(0, 0, 0, 0.1) */
$mainLhColor:       rgba(236, 236, 236, 0.253); /* #515151 */
$mainLhlColor:      rgba(255, 255, 255, 0.7);
$tagBg:             green;
$secColor:          $tagBg;
$leftWidth:         21%;
@import "grapesjs/src/styles/scss/main.scss";

// .#{$prefix} {
//   @each $cnum, $ccol in (one, $primaryColor), (two, $secondaryColor), (three, $tertiaryColor), (four, $quaternaryColor) {
//     &#{$cnum} {
//       &-bg {
//         background-color: $ccol;
//       }

//       &-color {
//         color: $ccol;

//         &-h:hover {
//           color: $ccol;
//         }
//       }
//     }
//   }
// }

*{
  padding: 0;margin:0;
  box-sizing: border-box
}

.block-icon-container {
	width: 100%;	
	display: inline-flex;
	justify-content: center;
	position: relative;
	padding: 8px 0;
	align-content: center;
  color:  inherit;
  svg{
    position: relative;
    left: 0;
    width: 50%;
    height: 40px;
    object-fit: contain;
    object-position: bottom;
    top: 0;
    padding: 0;
    margin: 0;
    color: currentColor;
    path, rect, circle{
      fill: currentColor
    }
  }
}
.icon{
  background-repeat: no-repeat;
    background-position: center;
  &.icon-sw{    
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 4h4v2H4V4zm12 15h4v2h-4v-2zM2 9h10v2H2V9zm12 0h6v2h-6V9zM4 14h6v2H4v-2zm8 0h10v2H12v-2zM10 4h12v2H10V4zM2 19h12v2H2v-2z"></path></g></svg>')
  }
  &.icon-device-desktop{
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect width="448" height="320" x="32" y="64" fill="none" stroke-linejoin="round" stroke-width="32" rx="32" ry="32"></rect><path stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M304 448l-8-64h-80l-8 64h96z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 448H144"></path><path d="M32 304v48a32.09 32.09 0 0032 32h384a32.09 32.09 0 0032-32v-48zm224 64a16 16 0 1116-16 16 16 0 01-16 16z"></path></svg>');
  }
  &.icon-device-tablet{
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M409 39c-4.5-4.5-10.6-7-16.9-7H119.9c-6.4 0-12.4 2.5-16.9 7-4.5 4.5-7 10.6-7 16.9V456c0 6.4 2.5 12.4 7 16.9 4.5 4.5 10.6 7 16.9 7H392c6.4 0 12.4-2.5 16.9-7 4.5-4.5 7-10.6 7-16.9V55.9c.1-6.3-2.4-12.4-6.9-16.9zm-153.4 9.7c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7c0-3.8 3.2-7 7-7zM256 470c-7.7 0-14-6.5-14-14.1 0-7.5 6.2-14 14-14 7.7 0 14.1 6.4 14.1 14-.1 7.6-6.4 14.1-14.1 14.1zm144-38H112V80h288v352z"></path></svg>');
  }
  &.icon-device-mobile{
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><rect width="256" height="480" x="128" y="16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" rx="48" ry="48"></rect><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 16h24a8 8 0 018 8h0a16 16 0 0016 16h64a16 16 0 0016-16h0a8 8 0 018-8h24"></path></svg>');
  }
  &.icon-text{
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M13 6v15h-2V6H5V4h14v2z"></path></g></svg>');
  }
  &.icon-headline{
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 72 77" fill="none"  xmlns="http://www.w3.org/2000/svg"><path d="M46.9772 29H42.1372V16.88H28.8172V29H24.0172V0.639998H28.8172V12.36H42.1372V0.639998H46.9772V29Z" fill="#9A9797" /></svg>');
  }
  &.icon-bullet-list{
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 109 44" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" fill="#9A9797" /><circle cx="5" cy="22" r="5" fill="#9A9797" /><circle cx="5" cy="39" r="5" fill="#9A9797" /><rect x="23" y="2" width="86" height="7" rx="3.5" fill="#9A9797" /><rect x="23" y="19" width="86" height="7" rx="3.5" fill="#9A9797" /><rect x="23" y="36" width="86" height="7" rx="3.5" fill="#9A9797" /></svg>');
  }
  &.icon-fullscreen{
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 3h2v6h-2V5h-4V3h4zM4 3h4v2H4v4H2V3h2zm16 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"></path></g></svg>');
  }
}
.gjs-clm-tags #gjs-clm-new {
  color: black;
}

