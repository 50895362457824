.confirmation-popup {
    position: absolute;
    top: 50px; /* Adjust this value to control the vertical position from the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    max-width: 500px;
    border-radius: 2px;
    padding: 22px 40px;
    text-align: center;
    /* Other styles as needed */
  }

  .confirmation-popup h2 { 
    margin-bottom: 20px;
  }

  .confirmation-popup p {
    margin-bottom: 34px;
  }

  .btn-wrapper button:first-of-type {
    margin-right: 30px;
  }

  .overlay {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    z-index: 999; /* A high z-index to ensure it's above other content */
    pointer-events: auto; /* Prevent interactions with the background */
    display: flex;
    justify-content: center;
    align-items: center;
  }