.makeStyles-root-1 {
    /* width: max-content; */
    display: flex;
    flex-direction: row;
    margin-left: -7px;
    margin-bottom: 8px;
}

.menuHeaderTxt {
    font-size: 24px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
}

.menumgmtMain input[type=text] {
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.MainMenubtn {
    margin-top: 10px;
}

.deleteIcon {
    display: flex;
    justify-content: right;
    color: #ccc;

}

.deleteIcon i {
    cursor: pointer;
}

.accmenu .arrowup,
.accmenu .arrowdown {
    margin-top: -1px;
    font-size: 18px;
    padding: 3px;
}

.suggestedcls {
    position: absolute;
    top: 27px;
    z-index: 100;
    background-color: #ffffff;
    border-radius: 5px;
    width: 100%;
}

.suggestedcls div {
    line-height: 26px;
    cursor: pointer;
}

.searchBoxcls {
    position: relative;
}

.reorderclass {
    display: flex;
    justify-content: right;
}

.validmsg {
    position: absolute;
    bottom: -5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start
}

.subTogglebtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}