.auth-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.auth-header .auth-header-top {
  background-color: var(--whitetColor);
  color: var(--secondary);
  box-shadow: 0px 4px 4px 0px #00000040;

}

.auth-header .auth-header-top .MuiToolbar-regular {
  min-height: 78px;
}

.auth-header .auth-header-top .logo {
  width: 96px;
  height: 36px;
  overflow: hidden;
}

.auth-header .auth-header-top .logo img {
  width: auto;
  max-width: 96px;
  height: 100%;
}

.auth-header .auth-header-top .auth-user {
  color: var(--bgRColor);
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.auth-header .auth-header-top .auth-user .username {
  padding-right: 24px;
}

.auth-header .auth-header-top .auth-user svg {
  color: var(--secondary);
}

.auth-card-wrap {
  display: flex;
  align-content: center;
  height: calc(100vh - 0px);
  align-items: center;
}

.auth-error-card {
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 0px;
}

.auth-error-card .MuiCardContent-root {
  text-align: center;
  padding: 0px;
}

.auth-error-card .MuiCardContent-root .icon {
  max-width: 53px;
  display: block;
  width: 100%;
  margin: 0 auto 8px;
}

.auth-error-card .MuiCardContent-root .auth-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 26px;
  color: var(--tertiary);
  margin-bottom: 8px;
  padding: 0px;
  font-weight: 500;
}

.auth-error-card .MuiCardContent-root .auth-msg {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--secondary);
  margin-bottom: 48px;
  letter-spacing: 0px;
}

.auth-error-card .MuiCardActions-root {
  justify-content: center;
  padding: 0px;
}

.auth-error-card .MuiCardActions-root .auto-button {
  width: 100%;
  padding: 8px 16px;
  background-color: var(--linkColor);
  color: var(--whitetColor);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
}